/**
 * 获取当前下单来源：供应端(4) | 运营端(1) 1-运营端 4- 供应商端 7- 服务端
 * @returns
 */
export const getSource = () => {
  const isSupply = window.location.href.includes("storemain");

  return {
    // sourceFrom: isSupply ? 4 : 1,
    sourceFormEnum: isSupply ? 4 : 1,
    uniPlatform: "web",
  };
};
