const getDefaultState = () => {
  return {};
};
const state = getDefaultState();
const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_DYNANICROUTER: (state, dynamicRouter) => {
    state.dynamicRouter = dynamicRouter;
  }
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
