import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // 整体评价
  {
    path: '/overallSituation',
    component: () => import('@/views/adminEvaluationMgt/overallSituation/index'),
    meta: { title: '整体情况' },
  },
  // 评价列表
  {
    path: '/list',
    component: () => import('@/views/adminEvaluationMgt/evaluationList/index'),
    meta: { title: '评价管理' },
  },
  // 自动评价规则
  {
    path: '/autoEvaluation',
    name: 'autoEvaluation',
    component: () => import('@/views/autoEvaluation/index.vue'),
    meta: { title: '自动评价' },
  },
  // 评价统计
  {
    path: '/evaluationStatistics',
    name: 'evaluationStatistics',
    component: () => import('@/views/evaluationStatistics/index.vue'),
    meta: { title: '评价统计' },
  },
  // 评价列表详情页
  {
    path: '/detailEvaluationList',
    component: () => import('@/views/adminEvaluationMgt/evaluationList/detailEvaluationList'),
    meta: { title: '评价详情' },
  },
  // 评价列表详情页回复页
  {
    path: '/replyEvaluationList',
    component: () => import('@/views/adminEvaluationMgt/evaluationList/replyEvaluationList'),
    meta: { title: '评价回复' },
  },

  {
    path: '/orderDetail',
    component: () => import('@/views/orderMgt/orderList/orderDetail'),
    meta: { title: '订单详情' },
  },
  {
    path: '/aditionalPurchaseInfoDetail',
    component: () => import('@/views/orderMgt/orderList/aditionalPurchaseInfoDetail'),
    meta: { title: '加购产品详情' },
  },
  {
    path: '/afterSaleDetails',
    component: () => import('@/views/orderMgt/orderList/afterSaleDetails'),
    meta: { title: '售后详情' },
  },
  {
    path: '/replySupplyEvaluationList',
    component: () => import('@/views/orderMgt/orderList/replySupplyEvaluationList'),
    meta: { title: '评价回复' },
  },

  
];

const router = new VueRouter({
  mode: 'history',
  // 为微应用路由加上前缀，如果不加微应用路由切换会有问题
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/index/evaluationMgt' : process.env.BASE_URL,
  routes,
});

export default router;
