import axios from 'axios';
import { Notification } from 'element-ui';
import { getToken } from '@/utils/auth';
import { getSource } from '@/utils'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 15000 // request timeout
});

service.interceptors.request.use(
  config => {
    const token = getToken();
    if (process.env.NODE_ENV === 'development') {
      config.headers['accessToken'] =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgGIsw9n7ns7QseLueCGHP4yrQlbmdzKr8wCwaAmbu3Ul5IFG4wBdToBg7DSSNtUWt50tgfosEPOnuEaZCPa+P3Yt6paWxk767Tx+AoXQYJHubWWDO6qv2vKFEZ5/ytYR5qT2vX8KMIOXZM1eVfLtka4Ar2kPqFYYP0FPAKesmw2809xMcTXj/opthtJeqZxyRTFta2/i/2MFgk/jKvbjvfuFpppw34y2w7YDrCAAZNn4H1E1irB7L0p74YSZBGgFzg==.bf6fa3fe36cc9400fafbc6e13d7e7f624d0a3326';
    }
    if (config.data) {
      const data = (config.data && config.data.data) || {};
      if (data && typeof data === "object" && !Array.isArray(data)) {
        config.data.data = { ...data, ...getSource() };
      }
    }
    if (token) {
      config.headers['accessToken'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const data = response.data;
    if (response.config.responseType === "blob") {
      // eslint-disable-next-line consistent-return
      return response.data;
    }
    if (data.success) {
      return data;
    } else if (data.code === '401') {
      Notification.error({ title: '错误', message: data.description });
      setTimeout(() => {
        window.location = data.redirectUrl;
      }, 500);
      return Promise.reject(data.message);
    } else {
      Notification.error({ title: '错误', message: data.description });
      return Promise.reject(data.message);
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
