export default class commonFn {
  //获取时间区间
  getDateInterval(param) {
    //获得当前时间
    const date = new Date();
    //拼零
    const splicingZero = function(num) {
      if (num < 10) {
        return `0${num}`;
      } else {
        return num;
      }
    };
    const verQuarter = function(month) {
      let str = {
        start: '',
        end: ''
      };
      switch (month) {
        case 1:
        case 2:
        case 3:
          str.start = 1;
          str.end = 3;
          break;
        case 4:
        case 5:
        case 6:
          str.start = 4;
          str.end = 6;
          break;
        case 7:
        case 8:
        case 9:
          str.start = 7;
          str.end = 9;
          break;
        case 10:
        case 11:
        case 12:
          str.start = 10;
          str.end = 12;
          break;
        default:
          break;
      }
      return str;
    };

    //起止时间数组
    let sPs = new Array();
    //获取当前时间戳
    let timeStamp = new Date().getTime();
    //获取当前年份
    let currentYear = date.getFullYear();
    //获取当前月份
    let currentMonth = date.getMonth();
    //获取当前日
    let currentDate = date.getDate();
    //获取当前天
    let currentDay = new Date().getDay();

    switch (param) {
      case 0:
        //添加至数组中返回
        sPs.push(currentYear + '-' + splicingZero(currentMonth + 1) + '-' + splicingZero(currentDate));
        sPs.push(currentYear + '-' + splicingZero(currentMonth + 1) + '-' + splicingZero(currentDate));
        break;
      case 1:
        const currentWeekStart = new Date(timeStamp - (currentDay - 1) * 60 * 60 * 24 * 1000);
        const currentWeekEnd = new Date(timeStamp + (7 - currentDay) * 60 * 60 * 24 * 1000);
        //添加至数组中返回
        sPs.push(currentWeekStart.getFullYear() + '-' + splicingZero(currentWeekStart.getMonth() + 1) + '-' + splicingZero(currentWeekStart.getDate()));
        sPs.push(currentWeekEnd.getFullYear() + '-' + splicingZero(currentWeekEnd.getMonth() + 1) + '-' + splicingZero(currentWeekEnd.getDate()));
        break;
      case 2:
        currentMonth++;
        let currentMonthFirstDate = new Date(currentYear, currentMonth, 1).getDate();
        let currentMonthEndDate = new Date(currentYear, currentMonth, 0).getDate();
        //添加至数组中返回
        sPs.push(currentYear + '-' + splicingZero(currentMonth) + '-' + splicingZero(currentMonthFirstDate));
        sPs.push(currentYear + '-' + splicingZero(currentMonth) + '-' + splicingZero(currentMonthEndDate));
        break;
      case 3:
        currentMonth++;
        let currentQuarterStart = verQuarter(currentMonth).start;
        let currentQuarterEnd = verQuarter(currentMonth).end;
        // console.log(currentQuarterStart);
        // console.log(currentQuarterEnd);

        //添加至数组中返回
        sPs.push(currentYear + '-' + splicingZero(currentQuarterStart) + '-' + splicingZero(new Date(currentYear, currentQuarterStart, 1).getDate()));
        sPs.push(currentYear + '-' + splicingZero(currentQuarterEnd) + '-' + splicingZero(new Date(currentYear, currentQuarterEnd, 0).getDate()));
        break;
    }

    if (sPs.length) {
      sPs[0] = sPs[0] + ' 00:00:00';
      sPs[1] = sPs[1] + ' 23:59:59';
    }

    return sPs;
  }
}
