import request from '@/utils/request'

export function getCityTree(data) {
  return request({
    url: 'misc-web-api/common/district/tree',
    method: 'post',
    data,
  })
}
export function getCityTree2(data) {
  return request({
    url: 'misc-web-api/common/district/twoTree',
    method: 'post',
    data,
  })
}
export function getCityList(data) {
  return request({
    url: "misc-web-api/common/district/queryById",
    method: 'POST',
    data,
  })
}