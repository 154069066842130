<template>
  <div id="app" style="background-color: #f7f7f7">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>
