<template>
  <el-cascader
    v-model="cityArr"
    :options="options"
    @change="handleChange"
    :props="cascaderProps"
    size="small"
    placeholder="请选择城市"
    ref="cascader"
    :disabled="disabled"
  >
  </el-cascader>
</template>

<script>
import { getCityTree2 } from '@/api/city';

export default {
  props: {
    val: {
      type: Array,
      default: () => [],
    },
    cityName: {
      type: String,
      default: '',
    },
    cityId: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cityArr: [],
      options: [],
      cascaderProps: {
        value: 'districtId',
        label: 'districtName',
        children: 'childs',
      },
    };
  },
  watch: {
    val: {
      deep: true,
      handler(newVal) {
        this.cityArr = [...(newVal || [])];
      },
    },
  },
  async created() {
    try {
      const res = await getCityTree2({});
      this.options = res.data || [];
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    handleChange(e) {
      console.log(e)
      const [node] = this.$refs.cascader.getCheckedNodes(true);
      const cityName = node.data.districtName || '';
      this.$emit('update:val', e);
      this.$emit('update:cityName', cityName);
      this.$emit('update:cityId', e[1] || '');
    },
    clearAll() {
      this.cityArr = [];
    },
  },
};
</script>

<style></style>
