import Vue from 'vue';
import 'normalize.css/normalize.css';

import CitySelect from './views/autoEvaluation/components/CitySelect'
import '../theme/index.css';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/index.scss';
import './public-path';

Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

//引入表格组件
import LTable from '@/components/LTable';
Vue.component('LTable', LTable);

//上传文件组件
import UploadFile from '@/components/UploadFile';
Vue.component('UploadFile', UploadFile);
Vue.component('CitySelect', CitySelect);

//工具类
import commonFn from '@/utils/common.js';
Vue.prototype.$commonFn = new commonFn();

Vue.config.productionTip = false;

let instance = null;
function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  // eslint-disable-next-line no-unused-vars
  const { container, onGlobalStateChange, setGlobalState, customProps } = props;
  // console.log("自定义的props参数", customProps);
  Vue.prototype.$actions = {
    onGlobalStateChange,
    setGlobalState
  };
  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app');
}

// 独立运行时
// eslint-disable-next-line no-underscore-dangle
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}
export async function mount(props) {
  console.log('[vue] props from main framework');
  render(props);
}
export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
  // router = null;
}
